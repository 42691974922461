<template>
    <div>
        <v-toolbar
            dense
            tabs
            color="primary darken-2"
            dark
            class="page_title"
        >
            <v-toolbar-title class="pa-0 pr-0 mt-1">
                <v-layout row >
                    <v-flex xs10 sm11 text-xs-left>
                        <a href="javascript: history.go(-1)" class="pa-0">
                            <v-btn flat fab small class="pa-0">
                                <i class="fas fa-arrow-left headline"></i>
                            </v-btn>
                        </a>
                        Forgotten Password
                    </v-flex>
                    <v-flex xs2 sm1 text-xs-right>
                        <router-link :to="$store.state.close_url" class="pa-0 text-xs-right">
                            <v-btn flat fab small class="pa-0">
                                <i class="fas fa-close headline"></i>
                            </v-btn>
                        </router-link>
                    </v-flex>
                </v-layout>
            </v-toolbar-title>
        </v-toolbar>

        <v-container>
            <v-layout row>
                <v-flex xs12 md8 offset-md2 lg6 offset-lg3>
                    <v-slide-y-transition mode="out-in">
                            <div>
                                <v-card class="blue lighten-4" style="" >
                                    <v-card-text style="font-size: 12px; ">
                                        <p class="dark--text subheading pa-0 ma-0 text-xs-center"><span> Please choose verification method and input your account contact (phone number or email)  </span></p>
                                    </v-card-text>
                                </v-card>
                                <v-card color="white pa-3 " style="background-color: rgb(255, 255, 255)!important" >
                                    <v-layout row>
                                        <v-flex xs12 md10 offset-md1>            
                                            <v-text-field class="pa-0 mt-2"
                                                id="email"
                                                label="Email"
                                                single-line
                                                color="teal"
                                            ></v-text-field>

                                            <v-text-field class="pa-0"
                                                id="phone_number"
                                                label="Phone Number"
                                                single-line
                                                color="teal"
                                            ></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <div class=" text-xs-center">
                                        <v-btn class="white--text elevation-6" round color="teal darken-2" >
                                            <span> Submit</span>
                                        </v-btn>
                                    </div>
                                </v-card>
                            </div>
                    </v-slide-y-transition>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    export default {
        mounted(){
          // executes these after the page has been mounted
          document.title = "PettyCash Savings | Forgotten Password"
          this.$store.commit('setCloseURL', "/")
      },

    }
</script>
